import API from "common/api";
import {
  OfferValidityRequestData,
  UseOfferRequestData,
  offerRequestBody,
  QuantiyBasedOfferRequest,
  AppliedoffersRequest,
} from "./offferModels";

export function getOffersAPI(data: offerRequestBody) {
  const offerRequestBodyParam =
    data &&
    (data.locationId !== "" || data.couponId !== "" || data.customerId !== "")
      ? `?customerId=${data.customerId}&locationId=${data.locationId}&couponId=${data.couponId}&orderType=${data.orderType}&visibleTo=${data.visibleTo}`
      : "";
      return {};
  // return API({
  //   method: "get",
  //   url: `/coupon/getActiveCoupons${offerRequestBodyParam}`,
  // });
}

export function checkOfferValidityAPI(details: OfferValidityRequestData) {
  return API({
    method: "get",
    url: "/coupon/validateCoupon",
    params: details,
  });
}

export function useOfferAPI(details: UseOfferRequestData) {
  return API({
    method: "post",
    url: "/coupon/redeemCoupon",
    data: details,
  });
}

export function checkOfferAPI(details: QuantiyBasedOfferRequest) {
  const offerRequestBodyParam =
    details &&
    (details.customerId !== "" ||
      details.itemId !== "" ||
      details.quantity !== "" ||
      details.locationId !== "" ||
      details.orderType !== "")
      ? `${details.locationId}?orderType=${details.orderType}&customerId=${details.customerId}&itemId=${details.itemId}&quantity=${details.quantity}`
      : "";
  //console.log(offerRequestBodyParam, "offerRequestBodyParam");
  return API({
    method: "get",
    url: `/coupon/item/offer/avail/${offerRequestBodyParam}`,
  });
}

export function appliedOffersAPI(details: AppliedoffersRequest) {
  const appliedOfferRequestBodyParam =
    details &&
    (details.locationId !== "" ||
      details.customerId !== "" ||
      details.offerIds !== "" ||
      details.orderId !== "")
      ? `${details.locationId}?customerId=${details.customerId}&offerIds=${details.offerIds}&orderId=${details.orderId}&orderAmount=${details.orderAmount}`
      : "";
  //console.log(appliedOfferRequestBodyParam, "offerRequestBodyParam");

  return API({
    method: "patch",
    url: `/coupon/item/offer/apply/${appliedOfferRequestBodyParam}`,
  });
}
