import axios from "axios";
import { showErrorToast } from "./utils/toastUtils";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 60000,
});

API?.interceptors?.response?.use(
  (res) => res,
  (err) => {
    // console.log("error response", err, err.response)s
    if (err?.response) {
      switch (err?.response?.status) {
        case 401:
          return err?.response;
        case 403:
          showErrorToast(`You don't have permission`);
          return err?.response;
        case 409:
          return err?.response;
        case 400:
          return err?.response;
        case 500:
          return err?.response;
        default:
          break;
      }
    } else {
      console.log('API ERROR:', err);
    }
    throw err;
  },
);

export default API;
